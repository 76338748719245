<template>
  <div class="organisation">
    <organisation/>
  </div>
</template>
<script>
// @ is an alias to /src

import Organisation from '../components/Organisation.vue'
export default {
  name: 'Organisations',
  components: {
    Organisation,
  }
}
</script>