<template>
  <div>
    <hr />
    <center>
      <h1>{{ h1 }}</h1>
    </center>
    <hr />
    <section v-if="!spiner" class="box1 container-lg box-center">
      <div
        style="text-align: center"
        v-if="erreur"
        class="alert alert-danger"
        role="alert"
      >
        {{ msg }}
      </div>
      <div
        style="text-align: center"
        v-else-if="msg2 !== ''"
        class="alert alert-success"
        role="alert"
      >
        {{ msg2 }}
      </div>
      <form class="xform">
        <center class="mb-3">
          <div class="nom-photo">{{ photo.name }}</div>
          <div
            @click="$refs.profilePhoto.click()"
            style="height: 150px; width: 150px; cursor: pointer"
            class="preview"
          >
          <input
            style="display: none"
            ref="profilePhoto"
            accept="image/*"
            type="file"
            @change="attachImage"
          />
            <div v-if="photo.name" style="margin: 0px" class="im">
              <img
                style="height: 100%; width: 100%"
                src=""
                ref="profilePhotoDisplay"
                class="w-100px"
              />
            </div>
            <div
              v-else
              @row-clicked="viewImage"
              striped
              hover
              :items="images"
              :fields="image_fields"
              class="im"
            >
              <img
                v-if="TabElement.lien_photo && importation"
                :src="photo"
                alt="Photo"
                width="150"
                height="150"
                srcset=""
              />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-camera-fill"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path
                  d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 
                0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 
                1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                />
              </svg>
            </div>
          </div>
        </center>
        <input
          style="display: none"
          ref="profilePhoto"
          accept="image/*"
          type="file"
          @change="attachImage()"
        />
        <div class="form-row">
          
          <div class="col-md mb-2">
            <label for="validationDefault001">Matricule</label>
            <input
              v-model.trim="matricule"
              type="text"
              class="form-control"
              id="validationDefault001"
              placeholder="Matricule"
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault01">Nom</label>
            <input
              v-model="titre"
              type="text"
              class="form-control"
              id="validationDefault01"
              placeholder="Titre"
              required
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1">Date d'adhésion</label>
            <input
              v-model="dateAdhesion"
              type="date"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Domaine</label>
            <input
              v-model="domaine"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder="Domaine"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Adresse</label>
            <input
              v-model="adresse"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder="Adressse"
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Téléphone</label>
            <input
              v-model.trim="telephone"
              type="number"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">E-mail</label>
            <input
              v-model.trim="mail"
              type="email"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Site Web</label>
            <input
              v-model.trim="site"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder="www.organisationname.com"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="validationDefault02">Facebook</label>
            <input
              v-model.trim="facebook"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Whatsapp</label>
            <input
              v-model.trim="whatsapp"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="inputState">Pays</label>
            <select
              v-model="pays"
              data-live-search="true"
              class="form-control selectpicker"
            >
              <option
                :data-tokens="item"
                v-for="item in nations"
                :key="item.index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="col-md mb-2">
            <label for="validationDefault02">Ville</label>
            <input
              v-model="ville"
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md mb-2">
            <label for="inputState"
              >Localitée
              <svg
                v-if="!refresh"
                @click="rafraichir()"
                style="color: blue; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-counterclockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                />
                <path
                  d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                />
              </svg>
              <div
                v-else
                class="spinner-border text-primary"
                style="width: 1.5rem; height: 1.5rem"
                role="status"
              >
                <span class="visually-hidden"></span>
              </div>
            </label>
            <select v-model="localite" class="form-control">
              <option
                v-for="item in all_lo"
                :key="item.index"
                :value="item.nom"
              >
                {{ item.nom }}
              </option>
            </select>
          </div>
          <div class="col-md mb-2">
            <label for="inputState">Zone</label>
            <select v-model="zone" class="form-control">
              <option value="zone 1" selected>Zone 1</option>
              <option value="zone 2" selected>Zone 2</option>
              <option value="zone 3" selected>Zone 3</option>
              <option value="zone 4" selected>Zone 4</option>
              <option value="zone 5" selected>Zone 5</option>
              <option value="zone 6" selected>Zone 6</option>
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md mb-2">
            <label for="exampleFormControlTextarea1"
              >Description de l'Organisation</label
            >
            <textarea
              v-model="description"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div class="custom-control custom-switch">
            <input
              type="checkbox"
              v-model="activation"
              class="custom-control-input"
              id="item"
            />
            <label class="custom-control-label" for="item">
              Compte: Activé|Désactivé
            </label>
          </div>

        <button
          class="btn float-right btn-primary"
          @click="envoyer()"
          type="submit"
        >
          Enregistrer</button
        ><br /><br />
      </form>
    </section>
    <div v-else class="chargement">
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { tabElemen } from "./l_organisation.vue";
import { listesPays } from './dataNomPays';
export default {
  name: "Organisation",
  data: () => {
    return {
      series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      nom: "BILLY",
      prenom: "",
      naissance: "",
      genre: "",
      domaine: "",
      photo: "https://www.rab-tech.com/dev_mode/js/api/",
      profession: "",
      dateAdhesion: "",
      categorie: "",
      fonction: "",
      bureau: "",
      matricule: "",
      ville: "",
      localite: "",
      zone: "",
      telephone: "",
      mail: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      skype: "",
      personnalite: "",
      organisation: "",
      fonctionOrganisation: "",
      image64: "",
      base64: "",
      pays: "Guinee",
      mod: false,
      Etatdeschamps: "",
      adresse: "",
      site: "",
      description: "",
      importation: false,
      h1: "Nouvelle Organisation",
      id_org: "",
      TabElement: tabElemen,
      msg: "",
      erreur: false,
      spiner: false,
      msg2: "",
      reponx_code: "",
      all_lo: "",
      refresh: false,
      nations: listesPays,
      activation: false,
      typeCompteUser: "",
      rpnx: "",
    };
  },
  methods: {
    all_localite: async function () {
      //this.spiner = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_localites.php")
        .then((response) => {
          this.all_lo = response.data.values;
          console.log(this.all_lo);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spiner = false;
        });
    },
    firstElement: function () {
      this.selectitem = this.reponx[0];
    },
    rafraichir: async function () {
      this.refresh = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_localites.php")
        .then((response) => {
          this.all_lo = response.data.values;
          console.log(this.all_lo);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.refresh = false;
        });
    },
    envoyer: async function () {
      this.spiner = true;
      console.log(this.base64);
      const ORGANISATION = new FormData();
      const COMPTE = new FormData();
      if (this.importation && this.TabElement) {
        ORGANISATION.set("id", this.TabElement.id);
      }
      ORGANISATION.set("image", this.base64);
      ORGANISATION.set("matricule", this.matricule);
      ORGANISATION.set("nom", this.titre);
      ORGANISATION.set("adresse", this.adresse);
      ORGANISATION.set("domaine_intervention", this.domaine);
      ORGANISATION.set("telephone", this.telephone);
      ORGANISATION.set("mail", this.mail);
      ORGANISATION.set("site_web", this.site);
      ORGANISATION.set("facebook", this.facebook);
      ORGANISATION.set("whatsapp", this.whatsapp);
      ORGANISATION.set("ville", this.ville);
      ORGANISATION.set("pays", this.pays);
      ORGANISATION.set("date_adhesion", this.dateAdhesion);
      ORGANISATION.set("zone", this.zone);
      ORGANISATION.set("localite", this.localite);
      ORGANISATION.set("description", this.description);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/save_organisation.php",
          ORGANISATION
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
          this.erreur = true;
          this.spiner = false;
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            if (
              (this.importation &&
                this.TabElement.is_valide == "0" &&
                this.activation == true) ||
              (this.importation &&
                this.TabElement.is_valide == "1" &&
                this.activation == false)
            ) {
              if (this.importation) {
                COMPTE.set("id", this.TabElement.id);
              }
              axios
                .post(
                  "https://www.rab-tech.com/dev_mode/js/api/switch_validite_organisation.php",
                  COMPTE
                )
                .then((response) => {
                  this.rpnx = response.data.message;
                  console.log(this.rpnx);
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
                  if (this.rpnx == "Une erreur s'est produite") {
                    //alert(this.rpnx);
                  }
                });
            }
            this.spiner = false;
            this.msg2 = "Enregistrement réussit.";
            this.erreur = false;
            this.titre = "";
            this.adresse = "";
            this.domaine_intervention = "";
            this.telephone = "";
            this.mail = "";
            this.site = "";
            this.facebook = "";
            this.whatsapp = "";
            this.ville = "";
            this.pays = "";
            this.dateAdhesion = "";
            this.zone = "";
            this.localite = "";
            this.description = "";
            this.$router.push({ path: "/Organisation-liste/0" });
          } else {
            this.msg = "Echec de l'enregistrement. Veillez réessayer svp !";
            this.erreur = true;
            this.spiner = false;
          }
        });
    },
    verifierModification: function () {
      let lien = document.URL;
      let lien2 = lien.split("/").pop();
      if (this.TabElement && lien2 == "1") {
        if (this.TabElement.is_valide == "0") {
          this.activation = false;
          //this.typeAction = "Désactiver"
        } else {
          this.activation = true;
          //this.typeAction = "Activer"
        }
        this.importation = true;
        if (this.TabElement.nom) {
          this.h1 = this.TabElement.nom;
        }
        if (this.TabElement.nom) {
          this.titre = this.TabElement.nom;
        }
        if (this.TabElement.matricule) {
          this.matricule = this.TabElement.matricule;
        }
        if (this.TabElement.adresse) {
          this.adresse = this.TabElement.adresse;
        }
        if (this.TabElement.domaine_intervention) {
          this.domaine = this.TabElement.domaine_intervention;
        }
        if (this.TabElement.telephone) {
          this.telephone = this.TabElement.telephone;
        }
        if (this.TabElement.mail) {
          this.mail = this.TabElement.mail;
        }
        if (this.TabElement.lien_photo) {
          this.photo = this.photo + this.TabElement.lien_photo;
        }
        if (this.TabElement.site_web) {
          this.site = this.TabElement.site_web;
        }
        if (this.TabElement.facebook) {
          this.facebook = this.TabElement.facebook;
        }
        if (this.TabElement.whatsapp) {
          this.whatsapp = this.TabElement.whatsapp;
        }
        if (this.TabElement.ville) {
          this.ville = this.TabElement.ville;
        }
        if (this.TabElement.pays) {
          this.pays = this.TabElement.pays;
        }
        if (this.TabElement.date_adhesion) {
          this.dateAdhesion = this.TabElement.date_adhesion;
        }
        if (this.TabElement.zone) {
          this.zone = this.TabElement.zone;
        }
        if (this.TabElement.localite) {
          this.localite = this.TabElement.localite;
        }
        if (this.TabElement.description) {
          this.description = this.TabElement.description;
        }
      }
    },
    attachImage() {
      let vm = this;
      this.photo = this.$refs.profilePhoto.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.$refs.profilePhotoDisplay.src = reader.result;
        }.bind(this),
        false
      );
      reader.readAsDataURL(this.photo);
      reader.onload = function (e) {
        vm.image64 = e.target.result.split(",");
        vm.base64 = vm.image64[1];
      };
      return vm.base64;
    },
  },
  created() {
    this.all_localite();
    this.verifierModification();
    let elementUser = JSON.parse(sessionStorage.getItem("authUser"));
    this.typeCompteUser = elementUser.type;
  },
};
</script>

